<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import Swal from "sweetalert2";
  import { DateTime } from "luxon";
  import { overlay } from "@/state/helpers";
  import Layout from "../../../layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import modalDeal from "@/views/apps/crm/modal-deal";
  import appConfig from "../../../../app.config";
  import {getEntity, callUrl, deleteEntity} from '@/helpers/api-apolo';
  import {formatPrice, getTimezone, saError, sumBusinessProductPrices} from '@/helpers/global-functions';
  import moment from "moment";
  import EmptyTableResult from "@/components/empty-table-result";

  export default {
    page: {
      title: "Leads",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    components: {
      modalDeal,
      Layout,
      PageHeader,
      Multiselect,
      flatPickr,
      EmptyTableResult
    },
    data() {
      return {
        title: "Leads",
        items: [{
            text: "CRM",
            href: "/",
          },
          {
            text: "Leads",
            active: true,
          },
        ],
        timeConfig: {
            enableTime: false,
            dateFormat: "Y-m-d",
        },
        showModalAddLeadFlag: false,
        date2: null,
        page: 1,
        perPage: 10,
        value: null,
        searchQuery: null,
        date: null,
        tagvalue: null,
        phases: [{value: '', label: 'Selecione a Fase'}],
        rangeDateconfig: {
          mode: "range",
          dateFormat: "d/m/Y",
        },
        pipelinesData: [],
        pipeline_phases: [],
        deals: [],
        selectPipeline: '',
        pipeline: {},
        query: '',
        selectAll: false,
        filter: {
          search: '',
          status: '',
          user_id: '',
          phase: '',
          date: null,
        },
        users: [],
        user: {},
        showFilter: false,
        dataCreate: {
          title: '',
          products: [],
          estimated_closing_date: '',
          description: '',
          pipeline_id: '',
          pipeline_phase_id: '',
          person: {
            name: '',
            telephone: '',
            email: ''
          }
        },
        telephone: '',
        productsData: [],
      };
    },
    computed: {
      pages() {
        if (this.page === 1 && !this.deals.length) {
          return [];
        }
        if (this.page === 1 && this.deals.length < this.perPage) {
          return [1];
        }
        if (this.page === 1 && this.deals.length === this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.deals.length || this.deals.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      }
    },
    methods: {
      ...overlay,
      async getAllPipelines() {
        try {
          this.pipelinesData = [];
          const data = await getEntity('pipeline');
          for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            obj.value = data[i].id;
            obj.label = data[i].name;
            this.pipelinesData.push(obj);
            if (obj.is_default) {
              this.selectPipeline = obj.value;
              this.pipeline = obj
            }
          }
          if (this.selectPipeline === '') {
            this.selectPipeline = this.pipelinesData[0].value;
            this.pipeline = this.pipelinesData[0]
          }
          this.phases = [{ value: '', label: 'Selecione a Fase' }];
          for (let i = 0; i < this.pipeline.phases.length; i++) {
            this.phases.push({value: this.pipeline.phases[i].id, label: this.pipeline.phases[i].name});
          }
          return true;
        } catch (error) {
          console.log(error.message);
          return false;
        }
      },
      selectUser(user) {
        this.user = user;
        this.filter.user_id = user;
      },
      clearFilter() {
        this.filter = {
          search: '',
          status: '',
          user_id: '',
          phase: '',
          date: null,
        };
        this.user = {};
        this.query = '';
        this.getAllDeals(true);
      },
      async getAllDeals(reset = false) {
        try {
          this.changeShowOverlay({show: true});

          const params = [`respect_phase_filters=false`, `page=${this.page}`, `limit=${this.perPage}`];
          if (this.query !== '') {
            params.push(`query=${encodeURI(this.query)}`);
          }
          if (this.filter.status !== '') {
            params.push(`status=${encodeURI(this.filter.status)}`);
          }
          if (this.filter.phase !== '') {
            params.push(`pipeline_phase_id=${encodeURI(this.filter.phase)}`);
          }
          if (this.filter.user_id !== '') {
            params.push(`attendant_id=${encodeURI(this.filter.user_id)}`);
          }
          if (this.filter.date !== null) {
            const [startDateString, endDateString] = this.filter.date.split(' to ');
            const startDate = moment(startDateString, 'DD/MM/YYYY').startOf('day');
            const endDate = endDateString ? moment(endDateString, 'DD/MM/YYYY').endOf('day') : moment(startDateString, 'DD/MM/YYYY').endOf('day');
            const begin_date = startDate.format('YYYY-MM-DDTHH:mm:ss');
            const end_date = endDate.format('YYYY-MM-DDTHH:mm:ss');
            params.push(`date_begin=${encodeURI(begin_date)}`);
            params.push(`date_end=${encodeURI(end_date)}`);
          }

          this.showFilter = false
          let dataM = await callUrl({}, '/pipeline/' + this.selectPipeline + '/business?' + params.join('&'), 'get')
          if (!dataM) {
            dataM = [];
          }
          if (reset) {
            this.deals = []
          }
          const now = DateTime.now().setZone(getTimezone());
          for (let i = 0; i < dataM.length; i++) {
            let obj = dataM[i];
            obj.vip = false;
            if (dataM[i].vip) {
              obj.vip = dataM[i].vip
            }
            let value = 0;
            for (let j = 0; j < dataM[i].products.length; j++) {
              value = parseInt(value + dataM[i].products[j].price * dataM[i].products[j].quantity);
              if (dataM[i].products[j].discount > 0) {
                value = parseInt(value - (value * dataM[i].products[j].discount / 100));
              }
            }
            obj.value = value;
            dataM[i].value = value;
            if (!dataM[i].actions) {
              dataM[i].actions = [];
            }
            if (!dataM[i].description) {
              dataM[i].description = "";
            }
            dataM[i].person_name = dataM[i].person.name;
            let diffDealPhaseMinutes = 0;
            const deal_pipeline_phase_id = obj.pipeline_phases[0].pipeline_phase_id;
            for (let j = 0; j < obj.pipeline_phases.length; j++) {
              if (obj.pipeline_phases[j].pipeline_phase_id === deal_pipeline_phase_id) {
                let exited_at = now;
                if (obj.pipeline_phases[j].exited_at && obj.pipeline_phases[j].exited_at !== '') {
                  exited_at = DateTime.fromISO(obj.pipeline_phases[j].exited_at).setZone(getTimezone());
                }
                let begun_at = DateTime.fromISO(obj.pipeline_phases[j].begun_at).setZone(getTimezone());
                diffDealPhaseMinutes = diffDealPhaseMinutes + exited_at.diff(begun_at, 'minutes').minutes;
              }
            }
            obj.phase = ''
            for (let j = 0; j < this.pipeline.phases.length; j++) {
              if (this.pipeline.phases[j].id === deal_pipeline_phase_id) {
                obj.phase = this.pipeline.phases[j].name;
                break;
              }
            }
            obj.updated = DateTime.fromISO(obj.updated_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
            obj.created = DateTime.fromISO(obj.created_at).setZone(getTimezone()).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
            const minutes = parseInt(diffDealPhaseMinutes)
            let hours = 0;
            if (minutes > 60) {
              hours = parseInt(minutes / 60);
            }
            let days = 0;
            if (hours > 24) {
              days = parseInt(hours / 24);
            }
            let months = 0;
            if (days > 0 && days > 30) {
              months = parseInt(days / 30);
            }
            let years = 0;
            if (months > 0 && months > 12) {
              years = parseInt(months / 12);
            }
            obj.date_diff_value = 0;
            obj.date_diff_type = 'Minuto';
            obj.date_diff_flag = '';
            if (minutes > 0 && hours === 0) {
              obj.date_diff_value = minutes;
              if (minutes === 1) {
                obj.date_diff_type = 'Minuto';
              } else {
                obj.date_diff_type = 'Minutos';
              }
              obj.date_diff_flag = 'badge-soft-success';
            } else if (hours > 0 && days === 0) {
              obj.date_diff_value = hours;
              if (hours === 1) {
                obj.date_diff_type = 'Hora';
              } else {
                obj.date_diff_type = 'Horas';
              }
              obj.date_diff_flag = 'badge-soft-success';
            } else if (days > 0 && months === 0) {
              obj.date_diff_value = days;
              if (days === 1) {
                obj.date_diff_type = 'Dia';
              } else {
                obj.date_diff_type = 'Dias';
              }
              obj.date_diff_flag = 'badge-soft-success';
            } else if (months > 0 && years === 0) {
              if (months > 0 && months <= 3) {
                obj.date_diff_flag = 'badge-soft-success'
                obj.date_diff_value = months;
                if (months === 1) {
                  obj.date_diff_type = 'Mês';
                } else {
                  obj.date_diff_type = 'Mêses';
                }
              } else if (months > 3 && months <= 6) {
                obj.date_diff_flag = 'badge-soft-warning'
                obj.date_diff_value = months;
                obj.date_diff_type = 'Mêses';
              } else if (months > 6) {
                obj.date_diff_flag = 'badge-soft-danger'
                obj.date_diff_value = months;
                obj.date_diff_type = 'Mêses';
              }
            } else if (years > 0) {
              obj.date_diff_flag = 'badge-soft-danger';
              obj.date_diff_value = years;
              if (years === 1) {
                obj.date_diff_type = 'Ano';
              } else {
                obj.date_diff_type = 'Anos';
              }
            }
            obj.features = [];
            for (let j = 0; j < obj.products.length; j++) {
              obj.features.push(obj.products[j].name)
            }
            obj.value_formatted = formatPrice(sumBusinessProductPrices(obj.products));
            obj.selected = false;
            this.deals.push(obj)
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      async getDealsToDelete() {
        const ids = [];
        const titles = [];
        for (let i = 0; i < this.deals.length; i++) {
          if (this.deals[i].selected) {
            ids.push(this.deals[i].id);
            titles.push(this.deals[i].title);
          }
        }
        return {
          ids,
          titles: titles.join(', ')
        }
      },
      async deleteMultiple() {
        const dealsToDelete = await this.getDealsToDelete();
        if (dealsToDelete.ids.length === 0) {
          saError('Selecione pelo menos 1 lead');
          return false;
        }
        // let html = 'Será removido os leads:<br><br><ul class="list-group">';
        // for (let i = 0; i < dealsToDelete.titles.length; i++) {
        //   html += `<li class="list-group-item">${dealsToDelete.titles[i]}</li>`;
        // }
        // html += '</ul><br>Essa é uma operação irreversível!!!';
        Swal.fire({
          title: "Você tem certeza?",
          html: "Será removido: <br><br><b>" + dealsToDelete.titles + "</b><br><br>Essa é uma operação irreversível!!!",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "SIM, pode remover!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              for (let i = 0; i < dealsToDelete.ids.length; i++) {
                await deleteEntity(dealsToDelete.ids[i], 'business')
              }
              this.changeShowOverlay({show: false});
              await Swal.fire("Sucesso!", "Removido com sucesso", "success");
              await this.getAllDeals(true);
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message)
              console.log(error.message)
              await this.getAllDeals(true);
            }
          }
        });
      },
      selectAllLeads() {
        this.deals.forEach(lead => {
          lead.selected = this.selectAll;
        });
      },
      reset () {
        this.query = ''
        this.getAllDeals(true)
      },
      formatTr (data) {
        if (data.status === 'Perdido') {
          return 'text-danger';
        }
        if (data.status === 'Fechado') {
          return 'text-success';
        }
        return '';
      },
      formatLink (data) {
        if (data.status === 'Perdido') {
          return 'link-danger';
        }
        if (data.status === 'Fechado') {
          return 'link-success';
        }
        return '';
      },
      async viewDeal(id) {
        await this.$router.push({path: '/apps/deal-details/' + id});
      },
      async getUsers() {
        try {
          getEntity('user')
            .then(async (data) => {
              this.users = [];
              if (data?.length > 0) {
                for (let i = 0; i < data.length; i++) {
                  let obj = data[i];
                  if (!obj.avatar) {
                    obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
                  }
                  obj.value = obj.id;
                  this.users.push(obj);
                }
              }
            });
        } catch (error) {
          saError(error.message);
        }
      },
      async duplicateLead(lead) {
        if (this.productsData.length === 0) {
          this.changeShowOverlay({show: true});
          await getEntity('product')
            .then((data) => {
              for (let i = 0; i < data.length; i++) {
                let obj = data[i];
                obj.product_id = data[i].id;
                obj.value = data[i].id;
                obj.label = data[i].name + ' - ' + formatPrice(data[i].price);
                this.productsData.push(obj)
              }
              this.changeShowOverlay({show: false});
            });
        }
        this.dataCreate = {
          duplicate_lead: true,
          business_id: lead.id,
          title: lead.title,
          products: this.productsData.filter(productData => lead.products.some(p => p.product === productData.id)),
          estimated_closing_date: lead.estimated_closing_date = moment(lead.estimated_closing_date).isBefore(moment()) ? moment().add(1, 'day').format('YYYY-MM-DD') : lead.estimated_closing_date,
          description: lead.description,
          person: {
            id: lead.person.id,
            name: lead.person.name,
            telephone: lead.person.telephone,
            email: lead.person.email,
          }
        }
        this.telephone = lead.person.telephone;
        this.showModalAddLeads();
      },
      closeModal() {
        this.showModalAddLeadFlag = false;
        this.dataCreate = {
          title: '',
          products: [],
          estimated_closing_date: '',
          description: '',
          pipeline_id: '',
          pipeline_phase_id: '',
          person: {
            name: '',
            telephone: '',
            email: ''
          }
        }
      },
      showModalAddLeads() {
        this.showModalAddLeadFlag = true;
      },
      savedBusiness(pipelineId) {
        this.selectPipeline = pipelineId;
        this.dataCreate = {
          title: '',
          products: [],
          estimated_closing_date: '',
          description: '',
          pipeline_id: '',
          pipeline_phase_id: '',
          person: {
            name: '',
            telephone: '',
            email: ''
          }
        }
      },
    },
    async mounted() {
      this.changeShowOverlay({show: true});
      this.getUsers();
      await this.getAllPipelines()
      this.changeShowOverlay({show: false});
    },
    watch: {
      page() {
        this.getAllDeals(true);
      },
      async query(value) {
        if (value === '' || value.length === 0) {
          await this.getAllDeals(true)
        }
      },
      async selectPipeline(value) {
        if (value && value !== '') {
          for (let i = 0; i < this.pipelinesData.length; i++) {
            if (this.pipelinesData[i].id === value) {
              this.pipeline = this.pipelinesData[i];
              break;
            }
          }
          this.clearFilter();
          this.phases = [{ value: '', label: 'Selecione a Fase' }];
          for (let i = 0; i < this.pipeline.phases.length; i++) {
            this.phases.push({value: this.pipeline.phases[i].id, label: this.pipeline.phases[i].name});
          }
          await this.getAllDeals(true);
        }
      },
    }
  }
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="leadsList">
          <div class="card-header border-0 mt-1 mb-1">
            <div class="row g-4 align-items-center">
              <div class="col-lg-auto">
                <button type="button" class="btn btn-success add-btn" @click="showModalAddLeads">
                    <i class="ri-add-line align-bottom me-1"></i> Leads
                  </button>
              </div>
              <div class="col-sm-3">
                <div class="search-box">
                  <input type="text" class="form-control search" placeholder="Buscar..." v-model="query" @keydown.enter.prevent="getAllDeals(true)" />
                  <i class="ri-search-line search-icon"></i>
                  <i class=" ri-close-line search-icon" style="right: 7px !important; left: unset !important; cursor: pointer !important;" v-show="query.length > 0" @click="reset()"></i>
                </div>
                <div class="form-text mt-0" style="position: absolute !important;" v-show="query.length > 0">Aperte ENTER para buscar.</div>
              </div>
              <div class="col-md-4">
                  <div class="d-flex hastck gap-2 flex-wrap">
                      <div class="d-flex align-items-center gap-2" style="width: -webkit-fill-available !important">
                          <Multiselect class="form-control" v-model="selectPipeline" :close-on-select="true" :searchable="true" :create-option="true" :options="pipelinesData" />
                      </div>
                  </div>
              </div>
              <div class="col-sm-auto ms-auto">
                <div class="hstack gap-2">
                  <button class="btn btn-soft-danger" @click="deleteMultiple">
                    <i class="ri-delete-bin-2-line"></i>
                  </button>
                  <button type="button" class="btn btn-info" data-bs-toggle="offcanvas" href="#offcanvasExample" @click.prevent="showFilter = true">
                    <i class="ri-filter-3-line align-bottom me-1"></i> Filtros
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table table-striped align-middle" id="customerTable">
                  <thead class="table-primary">
                    <tr>
                      <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="checkAll" v-model="selectAll" @change="selectAllLeads" />
                        </div>
                      </th>
                      <th>Título</th>
                      <th>Fase</th>
                      <th>Status</th>
                      <th>Pessoa</th>
                      <th>Valor</th>
                      <th>Responsável</th>
                      <th>Produtos</th>
                      <th>Criado Em</th>
                      <th>Esta na fase</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(data, index) of deals" :key="index" :class="data.selected ? 'table-active' : ''">
                      <th scope="row">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="chk_child" v-model="data.selected" />
                        </div>
                      </th>
                      <td class="id" style="display: none">
                        <a href="javascript:void(0);" class="fw-medium link-primary">{{ data.id }}</a>
                      </td>
                      <td>
                        <a :class="formatLink(data)" href="#" @click.prevent="viewDeal(data.id)">{{ data.title }}</a>
                      </td>
                      <td :class="formatTr(data)">{{ data.phase }}</td>
                      <td :class="formatTr(data)">{{ data.status }}</td>
                      <td :class="formatTr(data)">{{ data.person_name }}</td>
                      <td :class="formatTr(data)">{{ data.value_formatted }}</td>
                      <td :class="formatTr(data)">{{ data.attendants[0] && data.attendants[0].user_name ? data.attendants[0].user_name : '-' }}</td>
                      <td :class="formatTr(data)">
                        <span v-for="(product, index) of data.products" :key="index">
                          &nbsp;<span class="badge badge-soft-primary">{{product.name}}</span>
                        </span>
                      </td>
                      <td :class="formatTr(data)">{{ data.created }}</td>
                      <td :class="formatTr(data)">{{ data.date_diff_value }} {{data.date_diff_type}}</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" @click.prevent="duplicateLead(data)" style="cursor:pointer">
                                <i class="ri-file-copy-2-line align-bottom me-2 text-muted"></i>Duplicar
                              </a>
                            </li>
<!--                            <li class="dropdown-divider"></li>-->
<!--                            <li>-->
<!--                              <a class="dropdown-item remove-item-btn" @click.prevent="deletePipeline(data)" style="cursor:pointer">-->
<!--                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover-->
<!--                              </a>-->
<!--                            </li>-->
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <EmptyTableResult v-show="deals.length == 0" />
                
                <div class="d-flex justify-content-end m-3">
                  <div class="pagination-wrap hstack gap-2">
                    <a class="page-item pagination-prev" href="#" @click.prevent="--page" v-if="page > 1">Anterior</a>
                    <a class="page-item pagination-prev disabled" href="#" v-if="deals.length && page == 1" @click.prevent="">Anterior</a>
                    <ul class="pagination listjs-pagination mb-0">
                      <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click.prevent="page = pageNumber"
                        :class="{active: pageNumber == page, disabled: pageNumber != page}">
                        <a class="page" href="#">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <a class="page-item pagination-next" href="#" @click.prevent="++page" v-if="deals.length == perPage">Próxima</a>
                    <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="deals.length && deals.length < perPage">Próxima</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel" v-show="showFilter" ref="modalFilter">
              <div class="offcanvas-header bg-light">
                <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                  Filtros de Leads
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>
              <!--end offcanvas-header-->
              <form action="" class="d-flex flex-column justify-content-end h-100">
                <div class="offcanvas-body">
                  <div class="mb-4">
                    <label for="datepicker-range"
                      class="form-label text-muted text-uppercase fw-semibold mb-3">Período</label>
                    <flat-pickr placeholder="Select date" v-model="filter.date" :config="rangeDateconfig"
                      class="form-control flatpickr-input" id="demo-datepicker"></flat-pickr>
                  </div>
                  <div class="mb-4">
                    <label for="status-select"
                      class="form-label text-muted text-uppercase fw-semibold mb-3">Status</label>
                    <Multiselect class="form-control" v-model="filter.status" :close-on-select="true" :searchable="true"
                      :create-option="false" :options="[
                        { value: '', label: 'Selecione o Status' },
                        { value: 'Aberto', label: 'Aberto' },
                        { value: 'Perdido', label: 'Perdido' },
                        { value: 'Fechado', label: 'Ganho' },
                      ]" />
                  </div>
                  <div class="mb-4">
                    <label for="phase-select"
                      class="form-label text-muted text-uppercase fw-semibold mb-3">Fase</label>
                    <Multiselect class="form-control" v-model="filter.phase" :close-on-select="true" :searchable="true"
                      :create-option="false" :options="phases" />
                  </div>
                  <div class="mb-4">
                    <label for="user-select"
                      class="form-label text-muted text-uppercase fw-semibold mb-3">Responsável</label>
                    <Multiselect class="form-control" v-model="user" placeholder="Selecione o Responsável" label="name" track-by="name" :close-on-select="true" :searchable="true" :create-option="false" :options="users" @select="selectUser">
                      <template v-slot:singleLabel="{ value }">
                        <div class="multiselect-single-label">
                          <img class="character-label-icon avatar" :src="value.avatar" alt="avatar"> {{ value.name }}
                        </div>
                      </template>
                      <template v-slot:option="{ option }">
                        <img class="character-option-icon avatar" :src="option.avatar" alt="avatar"> {{ option.name }}
                      </template>
                    </Multiselect>
                  </div>
                </div>
                <!--end offcanvas-body-->
                <div class="offcanvas-footer border-top p-3 text-center hstack gap-2">
                  <button class="btn btn-light w-100" @click.prevent="clearFilter" data-bs-dismiss="offcanvas">Limpar</button>
                  <button class="btn btn-success w-100" @click.prevent="getAllDeals(true)" data-bs-dismiss="offcanvas">Filtrar</button>
                </div>
                <!--end offcanvas-footer-->
              </form>
            </div>
            <!--end offcanvas-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <modal-deal
      :pipelinesData="pipelinesData"
      :pipelineData="pipeline"
      :showModal="showModalAddLeadFlag"
      :deal="dataCreate"
      title="Lead"
      v-on:closeModal="closeModal"
      v-on:savedBusiness="savedBusiness"
    />
  </Layout>
</template>
<style scoped>
.table-striped > tbody > tr.table-active:nth-of-type(odd) > * {
  --bs-table-accent-bg: unset;
}
.option__title {
  margin-left: 10px !important;
}
.character-option-icon {
  margin: 0 6px 0 0;
  height: 22px;
}

.character-label-icon {
  margin: 0 6px 0 0;
  height: 26px;
}
img.avatar {
  border-radius: 50%;
  background-color: #fff;
  height: 40px !important;
  width: 40px !important;
  min-height: 40px !important;
  min-width: 40px !important;
}
</style>
