<template>
    <b-modal v-model="showAddDealModal" :title="'Criar ' + title" hide-footer class="v-modal-custom" modal-class="zoomIn" centered size="xl" @hidden="resetModal">
          <form>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6 col-lg-6">
                  <div class="mb-3">
                    <label for="dealPersonName" class="form-label">Nome Cliente</label>
                    <Multiselect id="dealPersonName"
                                 :class="['form-control', validateField(personName).status ? 'is-valid' : 'is-invalid']"
                                 v-model="personName"
                                 :close-on-select="true"
                                 :searchable="true"
                                 :create-option="true"
                                 :options="personFound"
                                 @search-change="searchPersonByName"
                                 @change="nameChanged"
                                 placeholder="Digite o Nome"
                                 autocomplete="chrome-off"
                                 required
                    />
                    <div class="invalid-feedback" v-show="!validateField(personName).status">{{validateField(personName).msg}}</div>
                  </div>
                  <div class="mb-3">
                    <label for="dealTitle" class="form-label">Título</label>
                    <input type="text" :class="['form-control', validateField(dataCreate.title).status ? 'is-valid' : 'is-invalid']" id="dealTitle" v-model="dataCreate.title" placeholder="Digite o título" required>
                    <div class="invalid-feedback" v-show="!validateField(dataCreate.title).status">{{validateField(dataCreate.title).msg}}</div>
                  </div>
                  <div class="mb-3">
                    <label for="dealProducts" class="form-label">Produtos</label>
                    <Multiselect class="form-control" v-model="dataCreate.products"
                                 :close-on-select="false" :searchable="true" :create-option="false" mode="tags" track-by="label" label="label" :options="products" />
                    <div class="text-info"><a href="#" @click.prevent="createProduct">Criar Produto</a></div>
                  </div>
                  <div class="mb-3">
                    <label for="phase-select" class="form-label">Pipeline</label>
                    <Multiselect class="form-control" v-model="dataCreate.pipeline_id" :close-on-select="true" :searchable="true" :create-option="false" :options="pipelinesData" @change="changePipelineOnModal"/>
                    <div class="invalid-feedback" style="display: unset" v-show="!validateField(dataCreate.pipeline_id).status">{{validateField(dataCreate.pipeline_id).msg}}</div>
                  </div>
                  <div class="mb-3">
                    <label for="phase-select" class="form-label">Fase</label>
                    <Multiselect class="form-control" v-model="dataCreate.pipeline_phase_id" :close-on-select="true" :searchable="true" :create-option="false" :options="pipeline_phases" />
                    <div class="invalid-feedback" style="display: unset" v-show="!validateField(dataCreate.pipeline_phase_id).status">{{validateField(dataCreate.pipeline_phase_id).msg}}</div>
                  </div>
                  <div class="mb-3">
                    <label for="dueDate" class="form-label">Data Estimada de Fechamento</label>
                    <flat-pickr placeholder="Select date" v-model="dataCreate.estimated_closing_date" :config="timeConfig"
                                class="form-control flatpickr-input" id="adddate"></flat-pickr>
                  </div>
                  <div class="mb-3">
                    <label for="dealDescription" class="form-label">Descrição</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                              placeholder="Digite a Descrição" v-model="dataCreate.description"></textarea>
                  </div>
                </div>
                <div class="col-md-6 col-lg-6">
                  <div class="mb-3">
                    <label for="dealPersonEmail" class="form-label">Email Cliente</label>
                    <input type="email" :class="['form-control', validateField(dataCreate.person.email, 'email').status ? 'is-valid' : 'is-invalid']" id="dealPersonEmail" v-model="dataCreate.person.email" placeholder="Digite o Email" required>
                    <div class="invalid-feedback" v-show="!validateField(dataCreate.person.email, 'email').status">{{validateField(dataCreate.person.email, 'email').msg}}</div>
                  </div>
                  <div class="mb-3">
                    <label for="dealPersonTelephone" class="form-label">Telefone Cliente</label>
                    <vue-tel-input v-model="telephone" @validate="changePhone" :class="['form-control', validateField(dataCreate.person.telephone, 'telephone').status ? 'is-valid' : 'is-invalid']" mode="international" :inputOptions="{placeholder: 'Telefone'}"></vue-tel-input>
                    <!--                                <input type="text" :class="['form-control', validateField(dataCreate.person.telephone, 'telephone').status ? 'is-valid' : 'is-invalid']" id="dealPersonTelephone" v-model="dataCreate.person.telephone" placeholder="Digite o Telefone" required>-->
                    <div class="invalid-feedback" v-show="!validateField(dataCreate.person.telephone, 'telephone').status">{{validateField(dataCreate.person.telephone, 'telephone').msg}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer v-modal-footer">
              <button type="button" class="btn btn-outline-dark" @click.prevent="cancelNewDeal(true)">Fechar</button>
              <button type="button" class="btn btn-success" @click.prevent="saveNewDeal" :disabled="!validateForm()"><i class="ri-save-line align-bottom me-1"></i> Salvar</button>
            </div>
      </form>
    </b-modal>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { overlay } from "@/state/helpers";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {getEntity, updateEntity, saveEntity, callUrl} from '@/helpers/api-apolo';
import {formatPrice, saError, saSuccess} from '@/helpers/global-functions';
export default {
  name: "modal-deal",
  components: {
    Multiselect,
    flatPickr,
    VueTelInput
  },
  props: {
    title: {
      type: String,
      default: 'Negócio'
    },
    showModal: {
      type: Boolean,
      default: false
    },
    pipelinesData: {
      type: Array,
      default: () => {
        return []
      }
    },
    pipelineData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deal: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pipeline: {},
      showAddDealModal: false,
      timeConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      personFound: [],
      searchingPerson: false,
      selectPipeline: '',
      products: [],
      pipeline_phases: [],
      telephone: '',
      personName: '',
      dataCreate: {
        pipeline_id: '',
        title: '',
        products: [],
        estimated_closing_date: '',
        description: '',
        pipeline_phase_id: '',
        person: {
          name: '',
          telephone: '',
          email: ''
        }
      },
      saving: false
    }
  },
  methods: {
    ...overlay,
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
    },
    resetModal() {
      setTimeout(() => {
        if (this.saving) return;
        this.$emit('closeModal');
        this.cancelNewDeal(true);
      }, 500);
    },
    async createDeal() {
      if (this.products.length === 0) {
        await getEntity('product')
          .then((data) => {
            for (let i = 0; i < data.length; i++) {
              let obj = data[i];
              obj.product_id = data[i].id;
              obj.value = data[i].id;
              obj.label = data[i].name + ' - ' + formatPrice(data[i].price);
              this.products.push(obj)
            }
          });
      }
      this.dataCreate.pipeline_id = this.pipeline.id;
      this.pipeline_phases = [{ value: '', label: 'Selecione a Fase' }];
      for (let i = 0; i < this.pipeline.phases.length; i++) {
        this.pipeline_phases.push({value: this.pipeline.phases[i].id, label: this.pipeline.phases[i].name});
      }
    },
    createProduct() {
      this.$router.push('/apps/crm-add-product');
    },
    changePipelineOnModal(value) {
      if (value && value !== '') {
        for (let i = 0; i < this.pipelinesData.length; i++) {
          if (this.pipelinesData[i].id === value) {
            this.pipeline_phases = [{ value: '', label: 'Selecione a Fase' }];
            for (let x = 0; x < this.pipelinesData[i].phases.length; x++) {
              this.pipeline_phases.push({value: this.pipelinesData[i].phases[x].id, label: this.pipelinesData[i].phases[x].name});
            }
            break;
          }
        }
        this.dataCreate.pipeline_phase_id = '';
      }
    },
    changePhone(data) {
      if (data.valid) {
        this.dataCreate.person.telephone = data.number
      }
    },
    cancelNewDeal(type = false) {
      if (!this.deal || type) {
        this.dataCreate = {
          title: '',
          products: [],
          estimated_closing_date: '',
          description: '',
          pipeline_phase_id: '',
          person: {
            name: '',
            telephone: '',
            email: '',
          }
        }
        this.telephone = ''
        this.personName = ''
        this.personFound = []
        this.saving = false
      }
      this.showAddDealModal = false
    },
    async saveNewDeal() {
      this.saving = true;
      this.changeShowOverlay({show: true});
      let saveBody = JSON.parse(JSON.stringify(this.dataCreate));
      const pipelineId = saveBody.pipeline_id;
      let products = JSON.parse(JSON.stringify(this.dataCreate.products));
      saveBody.person.telephone = '+' + saveBody.person.telephone.replace(/\D/g,'');
      saveBody.origin = 'Painel';
      saveBody.products = [];
      if (saveBody.estimated_closing_date === '') {
        delete saveBody.estimated_closing_date;
      }
      for (let i = 0; i < products.length; i++) {
        for (let j = 0; j < this.products.length; j++) {
          let obj = {};
          if (products[i] === this.products[j].id) {
            obj = this.products[j];
            obj.product_id = this.products[j].id;
            saveBody.products.push(obj);
            break;
          }
        }
      }
      try {
        let result = {}
        if (saveBody.id) {
          result = await updateEntity(saveBody, 'business')
        } else {
          result = await saveEntity(saveBody, 'business')
        }
        this.changeShowOverlay({show: false});
        if (result.id) {
          saSuccess("Negócio salvo com Sucesso!");
          this.cancelNewDeal(true);
          this.$emit('savedBusiness', pipelineId);
        }
      } catch (error) {
        this.changeShowOverlay({show: false});
        console.log(error.message)
        saError(error.message)
      }
    },
    validateForm() {
      return !(!this.validateField(this.dataCreate.title).status || !this.validateField(this.dataCreate.person.name).status || !this.validateField(this.dataCreate.person.email).status || !this.validateField(this.dataCreate.person.telephone).status);
    },
    validateField(field, type = '') {
      let valid = !(!field || field.length === 0);
      if (!valid) {
        return {status: false, msg: 'Campo Obrigatório'};
      }
      if (type && type === 'email') {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(field)) {
          return {status: true, msg: ''};
        }
        return {status: false, msg: 'Formato do email Inválido'};
      }
      if (type && type === 'telephone') {
        if (/^\+[1-9]\d{10,14}$/.test(field)) {
          return {status: true, msg: ''};
        }
        return {status: false, msg: 'Formato do telefone Inválido'};
      }
      return {status: true, msg: ''}
    },
    cleanedArray(dataArray) {
      const uniqueArray = [...new Set(dataArray.map(JSON.stringify))];
      return uniqueArray.map(JSON.parse);
    },
    async searchPersonByName(value) {
      try {
        if (this.searchingPerson || !value || value.length < 3) {
          return;
        }
        this.searchingPerson = true;
        this.personFound = [];
        const params = ['page=1', 'limit=20', 'order=name:1', `query=${encodeURIComponent(value)}`];
        const dataM = await callUrl({}, `/person?${params.join('&')}`, 'get');
        if (dataM && dataM.length) {
          for (const key in dataM) {
            this.personFound.push({
              value: dataM[key].id,
              label: `${dataM[key].name} - ${dataM[key].email}`,
              data: dataM[key]
            });
          }
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        this.searchingPerson = false;
        this.personFound = this.cleanedArray(this.personFound);
      }
    },
    nameChanged(value) {
      return new Promise((resolve, reject) => {
        try {
          if (value && value.length) {
          const person = this.personFound.filter(person => person.data.id == value);
          if (person.length > 0) {
            setTimeout(() => {
              const flatPerson = JSON.parse(JSON.stringify(person[0]));
              this.personFound = [{
                value: flatPerson.data.name,
                label: flatPerson.data.name,
                data: flatPerson
              }];
              this.personName = flatPerson.data.name;
              this.dataCreate.person.email = flatPerson.data.email;
              this.dataCreate.person.telephone = flatPerson.data.telephone;
              this.telephone = flatPerson.data.telephone;
              resolve(true);
            }, 300);
          }
        }
        } catch (error) {
          reject(error);
        }
      });
    }
  },
  async mounted() {
    this.pipeline = this.pipelineData;
  },
  watch: {
    showAddDealModal(value) {
      if (!value) {
        this.$emit('closeModal');
      }
    },
    pipelineData(value) {
      this.pipeline = value
    },
    showModal(value) {
      if (value) {
        this.cancelNewDeal();
        this.createDeal();
        this.showAddDealModal = true;
      }
    },
    personName(value) {
      this.dataCreate.person.name = value;
      this.dataCreate.title = 'Negócio de ' + value;
    },
    async selectPipeline(value) {
      if (value && value !== '') {
        for (let i = 0; i < this.pipelinesData.length; i++) {
          if (this.pipelinesData[i].id === value) {
            this.pipeline = this.pipelinesData[i];
            break;
          }
        }
      }
    },
    deal: {
      async handler(val) {
        if (val) {
          await this.createDeal();
          this.dataCreate.person = val.person;
          this.dataCreate.estimated_closing_date = val.estimated_closing_date ? val.estimated_closing_date : null;
          this.dataCreate.description = val.description;
          this.dataCreate.products = [];
          for (let i = 0; i < val.products.length; i++) {

            this.dataCreate.products.push(val.products[i].id);
          }
          await this.searchPersonByName(val.person.name)
          this.nameChanged(val.person.id)
            .then(() => {
              this.dataCreate.title = val.title;
            })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
